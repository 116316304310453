<template>
  <v-app>
    <v-row>
      <v-col class="d-flex lightGray justify-center">
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>

export default {
  name: 'Main',
}
</script>
<style  scoped>
.auth-image-holder {
  overflow: hidden;
}
.auth-image{
  width: 100%;
  max-width: 700px;
  min-height: 400px;
}
</style>
